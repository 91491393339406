import React, { Component, Fragment } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Context from "../components/Context"
import SEO from "../components/Seo"

export default class NotFound extends Component {
  componentDidMount() {
    const { data } = this.props
    // current page
    const blog = data.blog

    const header = {
      currentName: blog.title,
      currentType: "blog"
    }
    if (this.context.data.header !== header) {
      this.context.set({ header })
    }

    const { mapdata } = blog
    if (this.context.data.mapdata !== mapdata) {
      this.context.set({ mapdata: { bbox: mapdata.bbox } })
    }
  }

  render() {
    const { data } = this.props

    // current page
    const blog = data.blog

    return (
      <Fragment>
        {/* SEO */}

        <SEO
          pageTitle={blog.name}
          pageUrl={this.context.data.metadata.siteUrl + "/"}
        />

        {/* END : SEO */}

        <div className="uk-background-secondary uk-light">
          <div className="uk-section uk-position-relative">
            <div className="uk-container uk-container-medium uk-animation-fade">
              <h3 className="uk-text-uppercase uk-margin-medium-bottom">
                Plan du site
              </h3>

              <ul className="uk-list uk-list-bullet">
                {// EVENTS
                blog.events &&
                  blog.events.map(event => {
                    const eventUrl = "/" + event.slug
                    return (
                      <li key={event.contentful_id}>
                        <Link className="uk-link-text" to={eventUrl}>
                          {event.title}
                        </Link>
                        <ul className="uk-list uk-list-bullet">
                          {// PARTS
                          event.parts &&
                            event.parts.map(part => {
                              const partUrl = eventUrl + "/" + part.slug
                              return (
                                <li key={part.contentful_id}>
                                  <Link
                                    className="uk-link-text"
                                    to={partUrl}
                                  >
                                    {part.name + " : " + part.title}
                                  </Link>
                                  <ul className="uk-list uk-list-bullet">
                                    {// DAYS
                                    part.days &&
                                      part.days.map(day => {
                                        const dayUrl = partUrl + "/" + day.slug
                                        return (
                                          <li key={day.contentful_id}>
                                            <Link
                                              className="uk-link-text"
                                              to={dayUrl}
                                            >
                                              {day.name + " : " + day.title}
                                            </Link>
                                          </li>
                                        )
                                      })
                                    // END DAYS
                                    }
                                  </ul>
                                </li>
                              )
                            })
                          // END PARTS
                          }
                        </ul>
                      </li>
                    )
                  })
                // END EVENTS
                }
              </ul>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

NotFound.contextType = Context

export const query = graphql`
  query {
    blog: contentfulBlog {
      contentful_id
      name
      title
      mapdata
      events {
        contentful_id
        name
        title
        slug
        parts {
          contentful_id
          name
          title
          slug
          days {
            contentful_id
            name
            title
            slug
          }
        }
      }
    }
  }
`
